window.app.factory('UserFactory', ['$rootScope', 'API', 'ErrFactory', 'DBFactory', 
                           function($rootScope,   API,   ErrFactory,   DBFactory) {

  const dbTable = 'users';

  class User {
    id: string;
    email: string;
    first_name: string;
    name: string;
    personal_number: string;
    phone_number: string;

    type: any;
    user_role: any;
    user_groups: any;

    acceptedPrivacyPolicy: any;
    address: any;

    location: any;

    license_plate: string;
    license_plate_country: string;
    license_plate_signature_url: string;

    lockdown_till: any;
    is_beta_mode: any;
    push_notifications_targets: any;
    features: any;
    vacation_info: any;

    constructor(params: any = {}) {
      this.id = params.id;
      this.email = params.email;
      this.first_name = params.first_name;
      this.name = params.name;
      this.personal_number = params.personal_number;
      this.phone_number = params.phone_number;

      this.type = params.type || void 0;
      this.user_role = params.user_role || void 0;
      this.user_groups = params.user_groups || [];

      this.acceptedPrivacyPolicy = params.accepted_privacy_policy || params.acceptedPrivacyPolicy || false;
      this.address = params.address || null;

      this.location = params.location;

      this.license_plate = params.license_plate || null;
      this.license_plate_country = params.license_plate_country || null;
      this.license_plate_signature_url = params.license_plate_signature_url || null;

      this.lockdown_till = params.lockdown_till || null;
      this.is_beta_mode = params.is_beta_mode || false;
 
      this.push_notifications_targets = params.push_notifications_targets || null;
      this.features = params.features || null;
      this.vacation_info = params.vacation_info || null;
    }

    saveLocal(callback = Function.prototype) {
      let user = Object.assign({}, this);
      return DBFactory.then((ds) => ds.db)
      .then((db) => db.put(dbTable, user))
      .then(() => callback())
      .catch(() => {
        let err = new ErrFactory('errors.localDB');
        callback(err);
        return Promise.reject(err);
      });
    };

    update() {
      return Promise.resolve(API.getMe())
      .catch(() => Promise.reject(new ErrFactory('errors.receiveUser')))
      .then((res) => {
        this.id = res.id || null;
        this.email = res.email || null;
        this.first_name = res.first_name || null;
        this.name = res.name || null;
        this.personal_number = res.personal_number || null;
        this.phone_number = res.phone_number || null;

        this.type = res.type || null;
        this.user_role = res.user_role || void 0;
        this.user_groups = res.user_groups || void 0;

        this.acceptedPrivacyPolicy = res.accepted_privacy_policy || res.acceptedPrivacyPolicy || false;
        this.address = res.address || null;

        this.location = res.location || null;

        this.license_plate = res.license_plate || null;
        this.license_plate_country = res.license_plate_country || null;
        this.license_plate_signature_url = res.license_plate_signature_url || null;

        this.lockdown_till = res.lockdown_till || null;
        this.is_beta_mode = res.is_beta_mode || false;
        this.push_notifications_targets = res.push_notifications_targets || null;
        this.features = res.features || null;
        this.vacation_info = res.vacation_info || null;
        return this;
      })
      .then(() => API.getSavedBackendLocale())
      .then(locale => {
        let localLang = localStorage.getItem('selectedLang');
        if (localLang && (!locale || locale !== localLang)) API.saveLocaleToBackend(localLang);
      })
      .then(() => this.saveLocal())
      .catch((err) => {
        console.error(err)
        return this;
      });
    };

    async remove(callback = Function.prototype) {
      var self;
      self = this;
      return await (new Promise(function(res, rej) {
        return res();
      })).then(() => db.remove(dbTable, self.id)
        .done(
          () => callback(null),
          () => callback(new ErrFactory('errors.localDB'))
        )
      );
    }

    static getLocal(id) {
      return DBFactory.then((ds) => ds.db)
      .then((db) => {
        if (id) return db.get(dbTable, id);
        else throw new ErrFactory('errors.localDB');
      })
      .then((res) => {
        if (res) return new this(res);
        else throw `User ${id} not found in local db.`;
      })
      .catch((err) => {
        if (err instanceof ErrFactory) throw err;
        else {
          console.error(err);
          throw new ErrFactory('errors.localDB');
        }
      })
    }

    static genId () {
      return Math.floor(Math.random() * 100);
    };

    static getMe() {
      return API.getMe()
      .then(res => User.getLocal(res.id)
        .catch(err => new User(res))
        .then(user => user.saveLocal().then(() => user))
      )
      .then(user => {
        if (user && user.push_notifications_targets && user.push_notifications_targets.device_fcm_token) {
          $rootScope.fcmToken = user.push_notifications_targets.device_fcm_token;
        }
        return user;
      })
      .catch(err => {
        console.error(err);
        if (err.status && err.status === 401) throw err;
        throw new ErrFactory('errors.receiveUser');
      });
    };

  }

  return User;
}]);
